import { IUserAgent } from "./../types/ua";
export const getAppleMeta = (router: any, pageProps?: any) => {
  if (pageProps) {
    if (pageProps.product) {
      if (pageProps.sale?.single_offer) {
        const tmp2 = `chooseapp://sales/${encodeURI(pageProps.sale.url)}`;
        return tmp2;
      }
      const tmp1 = `chooseapp://product/${encodeURI(pageProps.product.url)}`;
      return tmp1;
    } else if (pageProps.sale) {
      const path = router.asPath
        .replace("/", "")
        .replace("sale/", "sales/")
        .replace("tester/", "sales/")
        .replace("upcoming/", "sales/")
        .split("?")[0];
      let newLocation = `chooseapp://${encodeURI(path)}`;
      if (
        path.match(/(sales|sale)/) &&
        pageProps.sale &&
        !pageProps.sale.sale_started
      ) {
        newLocation = `chooseapp://${encodeURI(
          path.replace("sales", "preview")
        )}`;
      }

      return newLocation;
    }
  }
  const tmp = "chooseapp:/" + window.location.pathname;
  return tmp;
};

export const getStoreLink = (userAgent: IUserAgent) => {
  const urlIos =
    "https://itunes.apple.com/fr/app/choose-concept-store/id985799982?mt=8&ct=website";
  const urlAndroid =
    "https://play.google.com/store/apps/details?id=com.appchoose.choose.android&hl=fr&gl=FR";
  return userAgent && userAgent.isAndroid ? urlAndroid : urlIos;
};

export const openURL = (
  userAgent: IUserAgent,
  pathname: string,
  url: string,
  isAnotherDomain: boolean
) => {
  if (
    url.indexOf("https://applinks.") === -1 &&
    url.indexOf("https://www.applinks.") === -1
  ) {
    if (isAnotherDomain) {
      window.location.href === url
        ? window.location.reload()
        : (window.location.href = url);
    } else {
      if (userAgent && !userAgent.isAndroid) {
        if (pathname === "/home") {
          window.location.href =
            window.location.host.replace("https://www.", "https://applinks.") +
            "/home";
        } else {
          window.location.href = window.location.href.replace(
            "https://www.",
            "https://applinks."
          );
        }
        window.location.href = window.location.href.replace(
          "https://www.",
          "https://applinks."
        );
      } else {
        window.location.href = url;
      }
    }
  } else {
    const storeLink = getStoreLink(userAgent);
    try {
      window.top.location = storeLink as any;
    } catch (e) {
      // openURL(userAgent, pathname, storeLink, true)
    }
  }
};

export const openStore = (userAgent: IUserAgent, pathname: string) => {
  let tmp = [];
  let tryLink = false;
  const storeLink = getStoreLink(userAgent);
  const clearAllTimeOuts = () => {
    tmp.forEach(window.clearTimeout), (tmp = []);
  };
  ["pagehide", "beforeunload", "blur"].forEach((n) => {
    window.addEventListener(n, clearAllTimeOuts);
  }),
    tmp.push(
      window.setTimeout(() => {
        tryLink = true;
        try {
          window.top.location = storeLink as any;
        } catch (e) {
          openURL(userAgent, pathname, storeLink, true);
        }
      }, 1e3)
    ),
    tmp.push(
      window.setTimeout(() => {
        if (tryLink) {
          window.location.href =
            window.location.pathname +
            (window.location.search
              ? window.location.search
                  .replace("?openstore=1", "")
                  .replace("openstore=1", "")
              : "");
        }
      }, 2e3)
    );
};
