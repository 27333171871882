import Head from "next/head";
import * as React from "react";
import { canonical as CANONICAL, packageName } from "../../config";

const keywordsInitial =
  "men's Christmas gifts, holiday gifts for him, women's Christmas gifts, holiday gifts for her, Christmas gift inspiration, holiday inspiration, Christmas gifts, holiday gifts, Christmas gift ideas, holiday gift ideas, unique Christmas gifts, original holiday gifts, Christmas products, holiday products, Christmas love gifts, romantic Christmas gifts, premium Christmas brands, top holiday brands, Christmas gift brands, holiday gift brands, Christmas gift selection, holiday gift selection, fast Christmas delivery, quick holiday delivery, hassle-free Christmas returns, easy holiday returns";
const initialDescription =
  "Découvrez de belles marques, soigneusement sélectionnées. Livraison rapide et retour gratuit. Des idées cadeaux pour Noël";
const initialtitle = "Choose | Your daily drop of cool. | Christmas gifts";

export default React.memo(function MetaHeader({
  title,
  pageTitle,
  description,
  keywords,
  image,
  appleArguments,
  children,
  isSquare,
  canonical,
  noIndex,
}: {
  title?: string;
  children?: any;
  pageTitle?: string;
  isSquare?: boolean;
  description?: string;
  image?: string;
  keywords?: string;
  appleArguments?: string;
  canonical?: string;
  noIndex?: boolean;
}) {
  return (
    <Head>
      <title key="title">{pageTitle || "Choose"}</title>
      <meta
        key="description"
        name="description"
        content={description || initialDescription}
      />
      <link rel="icon" type="image/x-icon" href={`/favicon.png`} />
      {noIndex ? <meta name="robots" content="noindex" /> : null}
      <meta
        key="mobile-web-app-capable"
        name="mobile-web-app-capable"
        content="yes"
      />
      <meta
        key="application-name"
        name="application-name"
        content={title || initialtitle}
      />
      <meta
        key="keywords"
        name="keywords"
        content={keywordsInitial + (keywords || "")}
      />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      {canonical ? <link rel="canonical" href={canonical} /> : null}
      <meta property="al:ios:url" content={appleArguments} />
      <meta property="al:ios:app_store_id" content="985799982" />
      <meta property="al:ios:app_name" content="Choose" />
      <meta property="al:android:url" content={appleArguments} />
      <meta property="al:android:app_name" content="Choose" />
      <meta property="al:android:package" content={packageName} />
      <meta property="al:web:url" content={canonical || CANONICAL} />
      <meta
        name="apple-mobile-web-app-status-bar-style"
        content="black-translucent"
      />
      <meta
        key="apple-mobile-web-app-title"
        name="apple-mobile-web-app-title"
        content={title || initialtitle}
      />
      <meta
        key="apple-itunes-app"
        name="apple-itunes-app"
        content={`app-id=985799982, app-argument=${
          appleArguments || "chooseapp://"
        }`}
      />
      <meta key="og:locale" property="og:locale" content="fr_FR" />
      <meta key="og:type" property="og:type" content="website" />
      <meta key="fb:app_id" property="fb:app_id" content="1573578732899017" />
      <meta
        key="og:title"
        property="og:title"
        content={title || initialtitle}
      />
      <meta
        key="og:description"
        property="og:description"
        content={description || initialDescription}
      />
      <meta key="og:image:width" property="og:image:width" content="1200" />
      <meta
        key="og:image:height"
        property="og:image:height"
        content={!isSquare ? "630" : "1200"}
      />
      <meta
        key="og:image"
        property="og:image"
        content={
          image ||
          "https://public.choose.app/website/new/images/thumbnail-share-fb.png"
        }
      />
      {/* <meta key="og:url" property="og:url" content={this.getUrl} /> */}
      <link rel="alternate" href={appleArguments} />
      <meta key="og:site_name" property="og:site_name" content="Choose" />
      <meta
        key="og:image:alt"
        property="og:image:alt"
        content={description || initialDescription}
      />
      <meta key="twitter:card" name="twitter:card" content="summary" />
      <meta
        key="twitter:description"
        name="twitter:description"
        content={description || initialDescription}
      />
      <meta
        key="twitter:title"
        name="twitter:title"
        content={title || initialtitle}
      />
      <link
        rel="apple-touch-icon"
        href="https://public.choose.app/website/new/images/logo.png"
      />
      <meta
        key="twitter:image"
        name="twitter:image"
        content={
          image ||
          "https://public.choose.app/website/new/images/thumbnail-share-fb.png"
        }
      />
      {process.env.TYPE === "development" ? (
        <>
          <meta name="robots" content="nofollow" />
          <meta name="googlebot" content="noindex" />
        </>
      ) : null}
      {children}
    </Head>
  );
});
