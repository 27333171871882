export const trackPage = (page?: string, payload?: any) => {
  console.log(document.location);
  if (process.env.TYPE === "development") {
    console.log(
      "trackPage amplitude",
      page,
      (window as any).amplitude,
      payload
    );
  }

  if ((window as any).amplitude) {
    (window as any).amplitude.track(
      "View" + page,
      payload
        ? { ...payload, url: document.location.pathname }
        : {
            url: document.location.pathname,
          }
    );
  }
};
interface IAnalytics {
  user_properties: any;
  event_properties: any;
  event_time: string;
  event_name: string;
  session_id: string;
}
const putESData = (data: IAnalytics) => {
  if ((window as any).amplitude) {
    (window as any).amplitude.track(data.event_name, data.event_properties);
  }
};

export const processQueue = () => {
  try {
    if (typeof window !== "undefined") {
      let queue;
      const tmp = localStorage.getItem("queue");
      if (typeof tmp === "string" && tmp) {
        queue = JSON.parse(tmp);
        if (Array.isArray(queue) && queue.length > 0) {
          queue.forEach((c) => {
            putESData(c);
          });
          localStorage.removeItem("queue");
        }
      }
    }
  } catch (e) {
    //
    console.error(e);
  }
};

export const trackEventElasticSearch = (
  event_name: string,
  event_properties?: any
) => {
  // if (process.env.TYPE === "development") {
  let authToken;
  try {
    if (typeof window !== "undefined") {
      authToken = sessionStorage.getItem("token");
    }
  } catch (error) {
    //
  }
  const dataAnalytis: IAnalytics = {
    event_name,
    session_id:
      typeof (window as any).amplitude !== "undefined"
        ? "" + (window as any).amplitude.getInstance()._sessionId
        : "",
    event_time: new Date().toISOString(),
    event_properties,
    user_properties: {
      device: {
        appCodeName: window.navigator.appCodeName,
        appName: window.navigator.appName,
        appVersion: window.navigator.appVersion,
        hardwareConcurrency: window.navigator.hardwareConcurrency,
        language: window.navigator.language,
        platform: window.navigator.platform,
        userAgent: window.navigator.userAgent,
        vendor: window.navigator.vendor,
      },
    },
  };
  if (authToken) {
    putESData(dataAnalytis);
  } else {
    pushToQueue(dataAnalytis);
  }
};

const pushToQueue = (data: any) => {
  try {
    if (typeof window !== "undefined") {
      let queue;
      const tmp = localStorage.getItem("queue");
      if (typeof tmp === "string") {
        queue = JSON.parse(tmp);
      } else {
        queue = [];
      }
      queue.push(data);
      localStorage.setItem("queue", JSON.stringify(queue));
    }
  } catch (e) {
    //
    console.error(e);
  }
};

export const trackEventSegment = (
  event_name: string,
  event_properties?: any
) => {
  console.log(event_name, event_properties);
  if ((window as any).amplitude) {
    (window as any).amplitude.track(event_name, event_properties);
  }
};
